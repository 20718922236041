import React from "react";
import styled from "styled-components";
import Discord from "../Icons/Discord";
import Twitter from "../Icons/Twitter";
import img2 from "../assets/game.png";
import img1 from "../assets/melogo.png";
import Logo from "./Logo";

const ContainerModal = styled.div`
  width: 50%;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "black",
  border: "1px solid #FFF",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const Section = styled.section`
  min-height: 20vh;
  width: 100vw;
  background-color: black;
  position: relative;
  color: ${(props) => props.theme.body};

  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid ${(props) => props.theme.body};
`;

const LogoContainer = styled.div`
  margin-left: 1rem;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const IconList = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1rem auto;

  & > * {
    padding-right: 0.5rem;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const MenuItems = styled.ul`
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;

  @media (max-width: 70em) {
    grid-template-columns: repeat(1, 6fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 1rem;
    padding: 1rem;
  }
`;

const Item = styled.li`
  width: fit-content;
  cursor: pointer;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: ${(props) => props.theme.body};
  }
`;

const Bottom = styled.div`
width: 75%;
margin: 0 auto;
display: flex;
padding-bottom: 10px;
margin-top: -15px;
justify-content: left;
align-items: center;


a{
	text-decoration:underline;
}

@media (max-width: 70em){
width: 90%;
align-items: center;
justify-content: center;
text-align: center;



`;

const ContactButton = styled.button`

  all: unset;
  width: 120px;
  margin-top: -5px;
  font-size: ${(props) => props.theme.fontxm};
  background: transparent;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;


}


`;

const Footer = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <Section>
      <Container>
        <Left>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <IconList>
            <a
              href="https://discord.com/invite/fnzX46rNmY"
              target="blank"
              rel="noopener"
            >
              <Discord />
            </a>
            <a
              href="https://twitter.com/GreedP2E"
              target="blank"
              rel="noopener"
            >
              <Twitter />
            </a>
            <a
              href="https://medium.com/@GreedP2E"
              target="blank"
              rel="noopener"
            >
              <svg
                width="24"
                height="24"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
              >
                <path d="M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z" />
              </svg>
            </a>
            <a
              href="https://magiceden.io/marketplace/greed"
              target="blank"
              rel="noopener"
            >
              <img src={img1} height="24" width="24" />
            </a>
            <a
              href="https://gametrade.market/games/Greed"
              target="blank"
              rel="noopener"
            >
              <img src={img2} height="24" width="24" />
            </a>
          </IconList>
        </Left>
        <MenuItems>
          <Item onClick={() => scrollTo("home")}>Home</Item>
          <Item onClick={() => scrollTo("about")}>What is Greed?</Item>
          <Item onClick={() => scrollTo("roadmap")}>Roadmap</Item>
          <Item onClick={() => scrollTo("apartments")}>OG Avatars</Item>
          <Item onClick={() => scrollTo("team")}>Team</Item>

          <Item>
            <a
              href="https://greedp2e.gitbook.io/whitepaper/"
              target="blank"
              rel="noopener"
            >
              WhitePaper
            </a>
          </Item>

          <Item>
            <a href="mailto:support@greedp2e.io" target="_blank" rel="noopener">
              Contact Us
            </a>
          </Item>
          <a href="https://www.greedstudios.xyz/" target="blank" rel="noopener">
            <Item>Greed Studios</Item>
          </a>
        </MenuItems>
      </Container>
      <Bottom>
        <span>
          &copy; {new Date().getFullYear()} GREED. All rights reserved.
        </span>
      </Bottom>
    </Section>
  );
};

export default Footer;
