import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

const Item = styled.div`
  width: calc(20rem - 4vw);
  padding: 1rem 0;
  color: ${(props) => props.theme.body};
  margin: 2rem 1rem;
  position: relative;
  border: 0px solid white;
  border-radius: 5px;
  margin-top: 70px;
`;

const ImageContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.2);
  border: 0px solid black;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 40px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
`;

const BottomContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.2);
  border: 0px solid black;
  padding: 1rem;
  border-radius: 5px;
  margin-top: -4px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease;
  }
`;

const Imgcontaineran = styled(motion.div)`
  width: 100%;
`;

const Section = styled.section`
  min-height: 50vh;
  margin-top: -4px;
  width: 100vw;
  background-color: black;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
`;

const Title = styled(motion.h1)`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  margin-top: -6px;
  padding-top: 14px;
  padding-bottom: -10px;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  padding-x: 12.5%;
  margin: 2rem auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 856px) {
    justify-content: center;
  }
`;

const Name = styled.h2`
  font-size: ${(props) => props.theme.fontlg};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppparcase;
  color: ${(props) => props.theme.body};
`;

const Position = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  font-weight: 200;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TwitterContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: -154px;
  z-index: 40;
`;

const MemberComponent = ({ img, name = " ", position = 2 }) => {
  return (
    <Item>
      <ImageContainer>
        <Imgcontaineran
          whileInView={{ opacity: "100%" }}
          initial={{ opacity: "0%" }}
          transition={{
            duration: 1,
            delay: 0.4,
            type: "spring",
          }}
          viewport={{ once: true }}
        >
          <img src={img} alt={name} />
        </Imgcontaineran>
      </ImageContainer>

      <BottomContainer>
        <TextContainer>
          <Name>{name} </Name>
        </TextContainer>
        <TextContainer>
          <Position>{position}</Position>
        </TextContainer>
      </BottomContainer>
    </Item>
  );
};

const Team = () => {
  return (
    <Section id="team">
      <Container>
        <Title
          whileInView={{ scale: 1, opacity: "100%" }}
          initial={{ scale: 0, opacity: "0%" }}
          transition={{
            duration: 2,
            delay: 0.4,
            type: "spring",
          }}
          viewport={{ once: true }}
        >
          Team
        </Title>
        <Title
          whileInView={{ scale: 1, opacity: "100%" }}
          initial={{ scale: 0, opacity: "0%" }}
          transition={{
            duration: 2,
            delay: 0.4,
            type: "spring",
          }}
          viewport={{ once: true }}
        >
          Coming Soon
        </Title>
        {/* <div style={{ marginBottom: "100px" }}>
          <MemberComponent
            img={jamespfp}
            name="James Fairbanks"
            position="Founder"
          />
          <TwitterContainer>
            <a
              href="https://twitter.com/Ares_Greed"
              target="blank"
              rel="noopener"
            >
              <Twitter />
            </a>
          </TwitterContainer>
        </div>
      </Container>
      <Container>
        <div style={{ marginBottom: "100px" }}>
          <MemberComponent img={vikrampfp} name="Vikram" position="CTO" />
          <TwitterContainer>
            <a
              href="https://twitter.com/A_CryptoLord"
              target="blank"
              rel="noopener"
            >
              <Twitter />
            </a>
          </TwitterContainer>
        </div>

        <div style={{ marginBottom: "100px" }}>
          <MemberComponent img={tarquinpfp} name="Tarquin" position="CMO" />
          <TwitterContainer>
            <a
              href="https://twitter.com/TGBarry11"
              target="blank"
              rel="noopener"
            >
              <Twitter />
            </a>
          </TwitterContainer>
        </div>
        <div style={{ marginBottom: "100px" }}>
          <MemberComponent img={miraclepfp} name="Miracle" position="CCO" />
          <TwitterContainer>
            <a
              href="https://twitter.com/Miracle__Effect"
              target="blank"
              rel="noopener"
            >
              <Twitter />
            </a>
          </TwitterContainer>
        </div> */}
      </Container>
      <Container></Container>
    </Section>
  );
};

export default Team;
