import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import backgroundimageabout from "../../assets/AboutBackground.png";

const SectionColor = styled.section`
  width: 100vw;
  position: relative;
`;

const Section = styled.section`
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  background-image: url(${backgroundimageabout});
  background-image-width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Title = styled(motion.h1)`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

  @media (min-width: 1024px) {
    font-size: ${(props) => props.theme.fontxxxxl}; // increase size for desktop
  }
`;

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
  padding-top: 100px;
  margin: 0 auto;
  background-image: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  overflow: auto; // added this line

  display: flex;
  justify-content: left;
  align-items: center;

  @media (min-width: 1024px) {
    justify-content: center; // center for larger screens
  }

  @media (max-width: 64em) {
    justify-content: center;
  }
`;

const AboutText = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxl};
  color: ${(props) => props.theme.body};
  font-weight: 400;
  margin: 0.5rem 0;
  max-width: 80%;
  margin-x: auto;
  position: relative;
  z-index: 40;
  text-align: center;

  @media (max-width: 100%) {
    max-width: 60%;
    margin-left: 0px;
  }

  @media (max-width: 100%) {
    font-size: ${(props) => props.theme.fontlg};
  }

  @media (max-width: 100%) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const About = () => {
  return (
    <SectionColor>
      <Section id="about">
        <Title>What is Greed?</Title>

        <Container>
          <AboutText>
            Greed is an open-world P2E game born on the blockchain. It is a
            twisted dystopian future corrupted by the elites, set in 2028. In
            the Greed metaverse, the economy runs upon gambling, driving society
            into a reckless & addictive culture. <br />
            <br />
            After a city-wide blackout, a select few wake up from their haze and
            see the world how it truly is. <br />
            <br />
            Pick a side in Greed, and choose to fight the elites by hacking the
            economy, or work with them to crush the resistance in this
            incredibly immersive PVP experience.
          </AboutText>
        </Container>
      </Section>
    </SectionColor>
  );
};

export default About;
