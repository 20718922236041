import React, { useState } from "react";
import styled from "styled-components";
import Discord from "../Icons/Discord";
import Twitter from "../Icons/Twitter";
import Logo from "./Logo";

const Section = styled.section`
  width: 100vw;
  overflow-x: hidden;
`;

const NavBar = styled.nav`
  display: flex;
  z-index: 50;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  width: 100%;
  height: 3rem;

  .mobile {
    display: none;
  }

  @media (max-width: 64em) {
    .desktop {
      display: none;
    }
    .mobile {
      display: inline-block;
    }
  }
`;
const Menu = styled.ul`
  display: flex;
  z-index: 50;
  margin-left: -114px;
  justify-content: space-between;
  align-items: center;
  list-style: none;

  @media (max-width: 64em) {
    position: fixed;
    margin-left: 0px;
    top: ${(props) => props.theme.navHeight};
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: ${(props) => `calc(100vh - ${(props) => props.theme.navHeight})`};
    z-index: 500;
    backdrop-filter: blur(10px);

    transform: ${(props) =>
      props.click ? "translateY(0)" : `translateY(1000%)`};
    transition: all 0.3s ease;

    flex-direction: column;
    justify-content: center;
  }
`;

const MenuItem = styled.li`
  margin: 0 1rem;
  color: ${(props) => props.theme.body};
  font-size: ${(props) => props.theme.fontxs};
  cursor: pointer;
  border-radius: 10px;
  padding: 5px;
  margin-top: 5px;

  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: ${(props) => props.theme.text};
    transition: width 0.3s ease;
  }
  &:hover {
    transition: all 0.2s ease;
    transform: scale(1.2);
  }
  &:hover::after {
    width: 0%;
  }

  @media (max-width: 64em) {
    margin: 1rem 0;

    &::after {
      display: none;
    }
  }
`;

const IconList = styled.div`
  display: flex;
  position: fixed;
  margin-top: 10px;
  right: 15px;
  z-index: 100;
  align-items: center;
  text-align: center;
  padding-left: 1.75rem;

  & > * {
    padding-right: 0.5rem;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(50px);
      opacity: 0%;
    }
    100% {
      transform: translateY(0);
      opacity: 100%;
    }
  }
`;

const HamburgerMenu = styled.span`
  width: ${(props) => (props.click ? "2rem" : "1.5rem")};
  height: 2px;
  margin-top: -7px;
  z-index: 50;
  background: ${(props) => props.theme.body};

  position: absolute;
  top: 2rem;
  left: 50%;
  transform: ${(props) =>
    props.click
      ? "translateX(-50%) rotate(90deg)"
      : "translateX(-50%) rotate(0)"};

  display: none;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.3s ease;

  @keyframes FadeIn {
    0% {
      opacity: 0%;
    }
    100% {
      opacity: 100%;
    }
  }

  animation: 1.4s ease-out 0s 1 FadeIn;

  @media (max-width: 64em) {
    display: flex;
  }

  &::after,
  &::before {
    content: "";
    width: ${(props) => (props.click ? "1rem" : "1.5rem")};
    height: 2px;
    right: ${(props) => (props.click ? "-2px" : "0")};
    background: ${(props) => props.theme.body};
    position: absolute;
    transition: all 0.3s ease;
  }

  &::after {
    top: ${(props) => (props.click ? "0.3rem" : "0.5rem")};
    transform: ${(props) => (props.click ? "rotate(-40deg)" : "rotate(0)")};
  }
  &::before {
    bottom: ${(props) => (props.click ? "0.3rem" : "0.5rem")};
    transform: ${(props) => (props.click ? "rotate(40deg)" : "rotate(0)")};
  }
`;
const Navigation = () => {
  const [click, setClick] = useState(false);

  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

    setClick(!click);
  };

  return (
    <>
      <Section>
        <NavBar>
          <Logo />
          <HamburgerMenu click={click} onClick={() => setClick(!click)}>
            &nbsp;
          </HamburgerMenu>
          <Menu click={click}>
            <MenuItem onClick={() => scrollTo("home")}>| Home |</MenuItem>
            <MenuItem onClick={() => scrollTo("about")}>
              | What is GREED? |
            </MenuItem>
            <MenuItem onClick={() => scrollTo("roadmap")}>| Roadmap |</MenuItem>
            <MenuItem onClick={() => scrollTo("gallery")}>
              | OG Avatars |
            </MenuItem>
            <MenuItem onClick={() => scrollTo("team")}>| Team |</MenuItem>
            <MenuItem>
              <a
                href="https://greedp2e.gitbook.io/whitepaper/"
                target="blank"
                rel="noopener"
              >
                | WhitePaper |
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="https://www.greedstudios.xyz/"
                target="blank"
                rel="noopener"
              >
                | Greed Studios |
              </a>
            </MenuItem>
          </Menu>

          <div class="desktop"></div>
        </NavBar>
        <IconList>
          <a href="https://twitter.com/GreedP2E" target="blank" rel="noopener">
            <Twitter />
          </a>
          <a href="https://discord.gg/fnzX46rNmY" target="blank" rel="noopener">
            <Discord />
          </a>
        </IconList>
      </Section>
    </>
  );
};

export default Navigation;
