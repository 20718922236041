import { ThemeProvider } from "styled-components";
import "./App.css";
import Footer from "./components/Footer";
import NavBar from "./components/Navigation";
import About from "./components/sections/About";
import Gallery from "./components/sections/Gallery";
import Home from "./components/sections/Home";
import ImagesGallery from "./components/sections/ImagesGallery";
import Roadmap from "./components/sections/Roadmap";
import Team from "./components/sections/Team";
import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";

function App() {
  return (
    <div>
      <GlobalStyles />
      <ThemeProvider theme={light}>
        <NavBar />
        <Home />
        {/* <ImagesApartments /> */}
        <About />
        <ImagesGallery />
        <Roadmap />

        <Team />
        <Gallery />
        {/* <Faq /> */}
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
