import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/whitelogo.png";

const LogoText = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  color: ${(props) => props.theme.text};
  transition: all 0.2s ease;
  margin-left: 18px;
  margin-top: 6px;
  margin-right: -18px;
  position: relative;
  z-index: 50;

  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(0.3);
    }
    50% {
      opacity: 1;
      transform: scale(1.05);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }

  animation: 1.4s ease-out 0s 1 bounceIn;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const Logo = () => {
  return (
    <>
      <LogoText>
        <Link to="/">
          <img src={logo} alt="GREED" width="100px" />
        </Link>
      </LogoText>
    </>
  );
};

export default Logo;
