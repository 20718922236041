import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import roadmapimage from "../../assets/AboutBackground.png";

const Section = styled.section`
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  position: relative;
  background-image: url(${roadmapimage});
  background-image-width: 100%;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const SectionCover = styled.section`
  width: 100%;
`;

const TitleBackdrop = styled.div`
  background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  padding-top: 1rem;
  padding-top: 100px;
  padding-bottom: 7rem;
  width: 100%;
`;

const TitleBackdropreverse = styled.div`
  background-image: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  padding-top: 1rem;
  padding-bottom: 7rem;
  width: 100%;
`;

const Title = styled(motion.h1)`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Container = styled.div`
  width: 70%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
  }
`;

const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Items = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 90%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: start;

    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 5px;
      text-align: center;
    }

    p {
      border-radius: 5px;
    }
  }
  & > *:nth-of-type(2n) {
    justify-content: end;

    @media (max-width: 48em) {
      justify-content: center;
      margin: 1rem;
    }

    div {
      border-radius: 5px;
      text-align: center;

      @media (max-width: 48em) {
        border-radius: 5px;
        text-align: center;

        p {
          border-radius: 5px;
        }
      }
    }

    p {
      border-radius: 5px;
    }
  }
`;

const Item = styled(motion.li)`
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  display: flex;

  @media (max-width: 48em) {
    justify-content: flex-end !important;
  }
`;
const ItemContainer = styled.div`
  width: 40%;
  height: fit-content;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.body};

  img {
    width: 300px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1000px) {
      width: 150px;
    }
  }

  @media (max-width: 48em) {
    width: 100%;
  }
`;

const Box = styled.p`
  height: fit-content;
  color: ${(props) => props.theme.text};
  padding: 1rem;
  position: relative;
  border: 0px solid ${(props) => props.theme.text};
`;

const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
  }
`;
const Text = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.body};

  font-weight: 400;
  margin: 0.5rem 0;

  @media (max-width: 1200px) {
    font-size: ${(props) => props.theme.fontxs};
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const RoadMapItem = ({
  title,
  subtext1,
  subtext2,
  subtext3,
  subtext4,
  img,
  addToRef,
}) => {
  return (
    <Item
      ref={addToRef}
      whileInView={{ opacity: "100%" }}
      initial={{ opacity: "0%" }}
      transition={{
        duration: 1.4,
        delay: 0.1,
      }}
      viewport={{ once: true }}
    >
      <ItemContainer>
        <SubTitle>{title}</SubTitle>
        <Box>
          <Text>{subtext1}</Text>
          <Text>{subtext2}</Text>
          <Text>{subtext3}</Text>
          <Text>{subtext4}</Text>
        </Box>
      </ItemContainer>
    </Item>
  );
};

const Roadmap = () => {
  return (
    <Section id="roadmap">
      <SectionCover>
        <TitleBackdrop>
          <Title
            whileInView={{ scale: 1, opacity: "100%" }}
            initial={{ scale: 0, opacity: "0%" }}
            transition={{
              duration: 2,
              delay: 0.4,
              type: "spring",
            }}
            viewport={{ once: true }}
          >
            Roadmap
          </Title>
        </TitleBackdrop>
        <Container>
          <Items>
            <Item>&nbsp;</Item>
            <RoadMapItem
              title="Q3 2023"
              subtext1="Launch Demo"
              subtext2="Lootbox Airdrop"
              subtext3="Pre-Seed Round"
            />
            <RoadMapItem
              title="Q4 2023"
              subtext1="Closed Beta Launch"
              subtext2="Collection Mint"
            />
            <RoadMapItem
              title="Q1 2024"
              subtext1="Seed Round"
              subtext2="Soft Launch"
            />
            <RoadMapItem
              title="Q2 2024"
              subtext1="Collection Mint"
              subtext2="$GREED ICO"
              subtext3="Official Launch"
            />
          </Items>
        </Container>
        <TitleBackdropreverse></TitleBackdropreverse>
      </SectionCover>
    </Section>
  );
};

export default Roadmap;
