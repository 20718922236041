

export const light ={

	body: "#fff",
	text: "#202020",
	bodyRgba : "255,255,255",
	textRgba: "32,32,32",

	carouselColor: '#FFFFFF',

	fontxs:'0,75em',
	fontsm:'0.875em',
	fontmd:'1em', // lem = 16px
	fontlg:'1.25em',
	fontxl:'1.5em',
	fontxxl:'3em',
	fontxxxl:'4am',

	fontButton:'0.875em',



	navHeight: '3rem',
}

export const dark ={

	body: "#202020",
	text: "#fff" ,
	bodyRgba : "32,32,32",
	textRgba: "255,255,255",

	carouselColor: '#EEEDDE',

	fontxs:'0,75em',
	fontsm:'0.875em',
	fontmd:'1em', // lem = 16px
	fontlg:'1.25em',
	fontxl:'2em',
	fontxxl:'3em',
	fontxxxl:'4am',

	fontButton:'0.875em',



	navHeight: '5rem',
}