import React from "react";
import styled from "styled-components";
import redimage from "../../assets/RedStrip.png";

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  background-color: black;
  margin-bottom: -10px;
`;

const ImageGallery = () => {
  return <StyledImage src={redimage} alt="Red Strip" />;
};

export default ImageGallery;
