import React from "react";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import img12 from "../../assets/1.jfif";
import img13 from "../../assets/10.jpg";
import img10 from "../../assets/11.jpg";
import img11 from "../../assets/12.jpg";
import img9 from "../../assets/2.jfif";
import img8 from "../../assets/3.jfif";
import img7 from "../../assets/4.jfif";
import roadmapimage from "../../assets/AboutBackground.png";
import "./gallery.css";

import { motion } from "framer-motion";

import { Navigation, Pagination } from "swiper";

const SectionColor = styled.section`
  width: 100vw;

  position: relative;
`;

const Title = styled(motion.h1)`
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 5%;
  text-align: center; // To ensure the text is centered if it wraps to a new line
`;

const Section = styled.section`
  display: flex; // Add flex property to Section
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  background-image: url(${roadmapimage});
  background-image-width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 140px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  width: 40%; // Change width to 50%
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    width: 90%;
    margin: 0 auto;
  }
`;

// Define a new container for the left side
const LeftContainer = styled.div`
  width: 50%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const StyledButton = styled.button`
  background-color: red;
  color: black;
  border-radius: 50px;
  padding: 20px 40px;

  margin: 10px 0;
  border: none;
  cursor: pointer;
`;

const TitleBackdrop = styled.div`
  background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  padding-top: 1rem;
  padding-top: 100px;
  position: absolute;
  top: 0;
  padding-bottom: 7rem;
  width: 100%;
`;

const TitleBackdropreverse = styled.div`
  background-image: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  padding-top: 1rem;
  position: absolute;
  bottom: 0;
  padding-bottom: 7rem;
  width: 100%;
`;

export default function Gallery() {
  return (
    <SectionColor>
      <Section id="gallery">
        <TitleBackdrop />
        <LeftContainer>
          <Title>
            Greed's Genesis collection of 111 OG Avatars. Purchase an Avatar,
            and receive the highest level of value within the Greed ecosystem,
            including GreedP2E, Greed Studios, and Embryo.
          </Title>
          <a
            href="https://medium.com/@GreedP2E/greeds-first-collection-and-everything-you-need-to-know-about-the-111-og-avatars-b4b93ccb142e"
            target="blank"
            rel="noopener"
          >
            <StyledButton>Learn More</StyledButton>{" "}
          </a>
          <a
            href="https://magiceden.io/marketplace/greed"
            target="blank"
            rel="noopener"
          >
            <StyledButton>Buy on Magic Eden</StyledButton>{" "}
          </a>
        </LeftContainer>
        <Container>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src={img9}
                style={{ maxWidth: "1000px", maxHeight: "80vh" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={img8}
                style={{ maxWidth: "1000px", maxHeight: "80vh" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={img7}
                style={{ maxWidth: "1000px", maxHeight: "80vh" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={img13}
                style={{ maxWidth: "1000px", maxHeight: "80vh" }}
              />
            </SwiperSlide>

            <SwiperSlide>
              <img
                src={img10}
                style={{ maxWidth: "1000px", maxHeight: "80vh" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={img11}
                style={{ maxWidth: "1000px", maxHeight: "80vh" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={img12}
                style={{ maxWidth: "1000px", maxHeight: "80vh" }}
              />
            </SwiperSlide>
          </Swiper>
        </Container>
        <TitleBackdropreverse />
      </Section>
    </SectionColor>
  );
}
