import React from "react";
import { Timeline, Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import styled from "styled-components";
import backgroundimagehome from "../../assets/GreedGraphic.png";
import logo from "../../assets/logogreednew.png";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
  background-color: ${(props) => props.theme.body};
  background-image: url(${backgroundimagehome});
  background-image-height: 100%;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: relative;
`;

const SectionOverlay = styled.section`
  max-height: 10vh;
  width: 100vw;
  overflow-x: hidden;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
`;

const Container = styled.div`
  width: 50%;
  min-height: 20vh;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const HomeButton = styled.button`
  all: unset;
  width: 120px;

  height: 30px;
  font-size: ${(props) => props.theme.fontlg};
  background: transparent;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 10;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &::after,
  &::before {
    content: "";
    position: absolute;
    border-radius: 15px;
    bottom: 0;
    right: 0;
    z-index: -99999;
    transition: all 0.4s;
  }

  &::before {
    transform: translate(0%, 0%);
    width: 100%;
    height: 100%;
    box-shadow: 0 0 50px 7px #fff;
    background: #28282d;
    border-radius: 10px;
  }

  &:after {
    transform: translate(10px, 10px);
    width: 35px;
    height: 35px;
    background: #ffffff15;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
    animation: glowbutton 1s infinite alternate;

    @keyframes glowbutton {
      0% {
        background: #ffffff15;
      }
      100% {
        background: #ffffff30;
      }
    }
  }

  &:hover::before {
    transform: translate(5%, 20%);
    width: 110%;
    height: 110%;
  }

  &:hover::after {
    border-radius: 10px;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
  }

  &:active::after {
    transition: 0s;
    transform: translate(0, 5%);
  }
`;

const Home = () => {
  return (
    <Section id="home">
      <Container>
        <Controller>
          <Scene
            duration={700}
            pin={true}
            indicators={false}
            triggerHook="onLeave"
          >
            {(progress) => (
              <Timeline totalProgress={progress} paused>
                <Tween
                  from={{ scale: 1, opacity: 1 }}
                  to={{ scale: 0.5, opacity: 0 }}
                >
                  <div>
                    <img
                      src={logo}
                      alt="GREED"
                      width="100%"
                      style={{ marginTop: "50px" }}
                    />
                  </div>
                </Tween>
              </Timeline>
            )}
          </Scene>
        </Controller>
      </Container>
      <Container>
        {/* <Link style={{ textDecoration: 'none'}} href="https://discord.com/invite/fnzX46rNmY" target="_blank" rel="noreferrer">
		<HomeButton>Join Discord </HomeButton>
		</Link> */}
        <SectionOverlay>.</SectionOverlay>
      </Container>
    </Section>
  );
};

export default Home;
